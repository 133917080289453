@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

/*
 * Lato Font Family 
 */

@font-face {
  font-family: "Lato";
  src: url("/src/assets/fonts/Lato/Lato-Thin.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/src/assets/fonts/Lato/Lato-ThinItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url("/src/assets/fonts/Lato/Lato-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/src/assets/fonts/Lato/Lato-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url("/src/assets/fonts/Lato/Lato-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/src/assets/fonts/Lato/Lato-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url("/src/assets/fonts/Lato/Lato-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/src/assets/fonts/Lato/Lato-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Lato";
  src: url("/src/assets/fonts/Lato/Lato-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("/src/assets/fonts/Lato/Lato-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

/*
 * Cardo Font Family 
 */
@font-face {
  font-family: "Cardo";
  src: url("/src/assets/fonts/Cardo/Cardo-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Cardo";
  src: url("/src/assets/fonts/Cardo/Cardo-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Cardo";
  src: url("/src/assets/fonts/Cardo/Cardo-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/*
 * Blackstone Font Family
 */
@font-face {
  font-family: "Blackstone";
  src: url("/src/assets/fonts/Blackstone/Blackstone.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.btn {
  @apply rounded-md bg-slate-600 px-4 py-3 font-bold text-slate-50 shadow-md hover:bg-slate-700;
}

.search-input {
  @apply flex-1 rounded-md border-2 border-solid border-zinc-200 p-4 shadow-sm focus:outline-slate-600;
}

.select {
  @apply focus:outline-none;
}

.shadow-tr {
  box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
}

.icon-sm {
  @apply max-h-[1rem] min-h-[1rem] min-w-[1rem] max-w-[1rem];
}

.icon {
  @apply max-h-[1.5rem] min-h-[1.5rem] min-w-[1.5rem] max-w-[1.5rem];
}

.icon-lg {
  @apply max-h-[1.75rem] min-h-[1.75rem] min-w-[1.75rem] max-w-[1.75rem];
}

.google {
  background: conic-gradient(
      from -45deg,
      #ea4335 110deg,
      #4285f4 90deg 180deg,
      #34a853 180deg 270deg,
      #fbbc05 270deg
    )
    73% 55%/150% 150% no-repeat;

  background-clip: text;
  -webkit-text-fill-color: transparent;
  color: transparent;
}

.hide-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}

.mask {
  @apply fixed bottom-0 left-0 right-0 top-0 z-10 bg-black/50;
}

.fancy-underline {
  @apply after:absolute after:top-full after:block after:w-full after:origin-right after:scale-0 after:transition-transform after:duration-300 after:ease-in-out after:content-[''] after:hover:origin-left after:hover:scale-100;
}

.group-fancy-underline {
  @apply after:absolute after:top-full after:block after:w-full after:origin-right after:scale-0 after:transition-transform after:duration-300 after:ease-in-out after:content-[''] after:group-hover:origin-left after:group-hover:scale-100;
}

.my-masonry-grid {
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
/* Style grid items (e.g. NewsletterComponent) */
.my-masonry-grid_column > li {
  margin-bottom: 30px;
}
